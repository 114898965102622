.Loading {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LoadingProgress {
  width: 100%;
}

.LoadingLogo {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LoadingLogo>img {
  width: 80%;
  max-width: 500px;
}