.RootView {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.RootView>.Content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RootView>.TopBar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TopBarContent {
  flex-grow: 1;
}

.TopBarButtons {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.TopBarLogo {
  height: 42px;
}

.RootView>.TopBar {
  display: flex;
}

.RootView>.BottomBar {
  display: none;
}

@media (max-width: 1024px) {
  .RootView>.TopBar {
    display: none;
  }

  .RootView>.BottomBar {
    display: flex
  }
}

.kitesurf-icon {
  font-family: kitesurf;
}